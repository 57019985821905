.side_bar_section > .btn img{
    filter:invert(1) !important;
}
.search_inner.open {
    height: 140px !important;}
.bonus_slider:before {
    background-size: contain;
}
.bonus_container{
    margin-top: 100px;
}

.btn.bonus {
    margin-top: 10px;
}
.bonus_pic{
    img{
        height: 300% !important;
        margin-bottom: -30px !important;
    }
}
.search_icon.leaderboard{
    @include recolor(#7df181)
}